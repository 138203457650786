#page.como-lo-hacemos {
  main {
    background-color: $color-1;
    color: white;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    header {
      position: relative;
      padding-block: 1.5rem;

      h2 {
        @extend %h2;
        z-index: 20;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;

        &::before {
          content: '';
          width: 30px;
          height: 30px;
          background-color: white;
        }
      }

      p {
        @extend %font-1;
        font-size: 6rem;
        white-space: nowrap;
        position: absolute;
        z-index: 10;
        opacity: 0.1;
        top: 50%;
        left: 60%;
        transform: translate(-30%, -50%);
        margin: 0;
        padding: 0;
      }
    }

    .oportunia {
      background-color: white;
      width: fit-content;
      align-self: flex-end;
      padding: 0.5rem 1rem 0.5rem 1.5rem;
      border-top-left-radius: 2rem;
      border-bottom-left-radius: 2rem;
    }

    section.mission {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-inline: 1rem;
      margin-block-start: 1.5rem;
      @extend %h3;
      align-items: end;

      img {
        width: 100%;
      }
    }

    section.content {
      background: linear-gradient(to bottom, $color-4, $color-4, 90%, white);
      padding-block: 1rem 3rem;
      padding-inline: 1rem;

      p {
        @extend %p;
      }

      picture {
        background-color: white;
        width: 100%;
        height: fit-content;
        padding: 1rem;
        display: flex;
        border-radius: 10px;

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      .slogan {
        margin-block: 2rem;
        display: flex;
      }
    }
  }

  section.slide-container {
    margin-block: 1.5rem;
    margin: 1.5rem 0;

    header,
    footer {
      display: grid;
      grid-template-columns: 8fr 4fr;
      padding: 1rem;
      width: 100%;
      align-items: center;

      .title {
        display: flex;
        flex-direction: column;
        span {
          @extend %font-1;
          font-size: 1.5rem;
          color: rgba($color: $color-3, $alpha: 0.5);
        }
      }

      .indication {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        i {
          font-size: 2rem;
        }

        svg {
          width: 2rem;
          height: 2rem;
          object-fit: contain;
        }

        p {
          @extend %p;
          text-transform: uppercase;
          margin-bottom: 0;
          margin-block-end: 0;
        }
      }
    }

    footer {
      .bullets {
        display: flex;
        flex-direction: row;
        gap: 0.3125rem;
        justify-self: center;

        i {
          color: $color-3;
        }
        svg {
          width: 1rem;
          height: 1rem;
          object-fit: contain;

          path {
            fill: $color-3;
          }
        }
      }
    }

    .slider {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: calc(100vh - 2rem);

      .slide {
        width: 100%;
        height: 100%;
        background-size: 95%;
        background-repeat: no-repeat;
        background-position-x: center;
        position: absolute;
        transform: translateX(100%); /* Hide slides initially */
        transition: transform 0.5s ease;

        /* Active slide */
        &.active {
          transform: translateX(0); /* Show active slide */
        }
      }
    }
  }
}
