.hover-menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  bottom: 0;
  background-color: white;
  padding: 0.5rem 1rem;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  border: solid 1px $color-3;
  border-radius: 10px;
  z-index: 100;

  .active {
    background-color: $color-1;
    border-radius: 50%;
    padding: 0.5rem;

    img {
      filter: invert(100%);
      width: 100%;
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    list-style: none;
    column-gap: 1rem;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      button {
        background-color: transparent;
        border: none;
      }
      img {
        width: 90%;
      }
    }
  }
}
