$color-1: #0063a7;
$color-2: #e99733;
$color-3: #58595b;
$color-4: #3684b9;

// UI
$table-color-1: #f0f6fb;
$table-color-2: $color-1;
$pasos-color-1: #fbead6;
$pasos-color-2: $color-2;
$pasos-numero: #fff200;
