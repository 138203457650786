/*

# abstracts
    ## colors
    ## typography

*/

/* # abstracts */
@import 'abstract/colors';
@import 'abstract/typography';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

p {
  margin: 1rem 0;
  margin-block: 1rem;
}

body {
  padding-bottom: 6rem;
  font-size: 16px;

  @extend %font-2;
}

.page {
  position: relative;
}

@import './pages/home';
@import './pages/que-ofrecemos';
@import './pages/como';
@import './pages/costo';
@import './pages/como-donar';
@import './components/hover-menu';
