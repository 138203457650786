#page.costo {
  main {
    header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem 1rem 1rem 0;
      padding-block: 1rem;
      padding-inline-end: 1rem;

      &::before {
        content: '';
        width: 50px;
        height: 30px;
        background-color: $color-1;
      }

      h2 {
        @extend %h2;
        padding-left: 1rem;
        line-height: 1.7rem;

        span {
          color: $color-1;
        }
      }

      .mainSite {
        width: 70%;
        display: flex;

        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }

    section {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-block-end: 1rem;

      picture {
        width: 90%;
        display: flex;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          object-fit: contain;
        }
      }

      .info {
        width: 70%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;

        picture {
          width: 80%;
        }

        p {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: black;
          @extend %p;
          font-weight: 700;
          text-transform: uppercase;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 0;
          background-color: white;
          padding: 1rem;
          border-radius: 50%;
          width: 40%;

          span {
            white-space: nowrap;
          }
        }
      }
    }
  }

  section.costs {
    display: flex;
    flex-direction: column;
    align-items: center;

    header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem;
      margin-block-end: 1.5rem;

      h3 {
        @extend %h3;
      }
      p {
        @extend %font-1;
        font-size: 0.875rem;
        color: $color-3;
        margin: 0;
        font-weight: 400;
      }
    }

    .cost-grid {
      width: 90%;
      border: solid 1px $color-3;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
      box-sizing: border-box;
      overflow: hidden;

      table {
        width: 100%;
        border-collapse: collapse;

        thead {
          background-color: $table-color-1;
          @extend %p;
          text-align: left;

          th {
            padding: 0.5rem 0.375rem;
            padding-inline: 0.375rem;
            padding-block: 0.5rem;
            font-size: 0.875rem;

            &:nth-child(1) {
              background-color: $color-1;
              color: white;
              text-transform: uppercase;
              text-align: left;
            }

            &:nth-child(2) {
              font-weight: 400;
            }
          }
        }

        tbody {
          tr {
            border-top: solid 1px $color-3;
            border-bottom: solid 1px $color-3;

            &.open-accordion {
              .detail {
                display: none;
              }

              &.active {
                .detail {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  p {
                    @extend %p;
                    font-weight: 700;
                    font-size: 1.125rem;

                    &.subtext {
                      color: $color-3;
                      font-size: 0.75rem;
                      font-weight: 400;
                    }
                  }
                }
              }
            }
          }

          td {
            p {
              margin: 0;
            }

            &:nth-child(1) {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              font-size: 1.125rem;
              min-height: 40px;
              vertical-align: middle;

              svg {
                margin-right: 1rem;
                width: 1.125rem;
                height: 1.125rem;
                object-fit: contain;
              }

              p {
                font-weight: 700;
                @extend %font-1;
              }
            }

            &:nth-child(2) {
              padding: 0.5rem;
              padding-inline: 0.5rem;
              padding-block: 0.5rem;
              border-inline: solid 1px $color-3;
              border-left: solid 1px $color-3;
              border-right: solid 1px $color-3;
            }

            &:nth-child(3) {
              display: flex;
              flex-direction: column;
              justify-content: end;
              padding: 0 0.5rem;
              padding-inline: 0.5rem;

              font-weight: 700;
              @extend %font-1;
              font-size: 1.125rem;

              p {
                white-space: nowrap;
              }
            }
          }
        }
      }

      h3 {
        margin-top: 1.5rem;
      }

      table.insert-autogestion {
        margin-top: 0.5rem;

        thead {
          tr {
            border-top: solid 1px $color-3;
          }
        }
      }

      .total {
        width: 100%;
        display: grid;
        grid-template-columns: 4fr 8fr;
        margin-top: 2rem;
        margin-block-start: 2rem;
        border-top: solid 1px black;

        .row {
          display: flex;
          flex-direction: row;
          align-items: center;

          p {
            margin: 0;
            white-space: nowrap;
          }
          &:nth-of-type(1) {
            background-color: $color-1;
            color: white;
            font-size: 1rem;
            @extend %font-1;
            font-weight: 700;
            padding: 0.5rem 1rem;
            padding-inline: 1rem;
            padding-block: 0.5rem;
          }

          &:nth-of-type(2) {
            background-color: $table-color-1;
            width: 100%;
            display: flex;
            justify-content: end;

            p {
              padding-right: 1rem;
              @extend %font-2;
              font-size: 1rem;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
