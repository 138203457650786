#page.que-ofrecemos {
  main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20vh;
    padding: 1rem;
    background-position: center center;
    background-size: contain;
    overflow: hidden;
    position: relative;

    h2 {
      color: white;
      z-index: 20;
      @extend %h2;
    }

    p {
      position: absolute;
      font-size: 10rem;
      color: white;
      text-transform: uppercase;
      white-space: nowrap;
      opacity: 0.1;
      z-index: 10;
      top: 50%; // Example to center vertically
      left: 50%; // Example to center horizontally
      transform: translate(-50%, -50%); // Center the text properly

      @extend %font-1;
    }
  }

  .propuesta-valor {
    display: flex;
    flex-direction: column;
    article {
      margin-block: 1rem;

      header {
        display: grid;
        grid-template-columns: 1fr 11fr;
        align-items: center;
        column-gap: 0.625rem;

        div {
          background-color: $color-1;
          width: 50px;
          height: 50px;
          aspect-ratio: 1 /1;
          position: relative;
          overflow: hidden;

          p {
            margin: 0;
            padding: 0;
            color: white;
            font-size: 2.875rem;
            position: absolute;
            right: 0;
            @extend %font-1;
            top: 0;
          }
        }

        h3 {
          color: $color-1;
        }
      }

      section {
        @extend %p;
        padding-inline: 1rem;
      }
    }
  }
}
