#page.home {
  main {
    position: relative;
    display: grid;
    grid-template-areas:
      'sidea logo logo sideb'
      'sidea main main sideb';
    width: 100%;
    height: 90vh;
    overflow: hidden;
    background-size: cover;

    .mainSite {
      width: 95%;
      display: flex;
      grid-area: logo;
      place-self: center center;

      .site-logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    %common-sides {
      height: 100%;
    }

    .side-a {
      position: relative;
      grid-area: sidea;
      width: 100%;
      height: 100%;

      img {
        @extend %common-sides;

        transform: translateX(-30px);
      }
    }

    .side-b {
      right: -1.5rem;
      grid-area: sideb;

      img {
        @extend %common-sides;

        transform: translateX(30px);
      }
    }

    picture.main-content {
      grid-area: main;
      justify-self: center;
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  section {
    padding: 1rem;
    margin-top: 1rem;
  }

  .founder {
    padding: 0;

    h2 {
      @extend %h2;

      padding: 1rem;
      color: $color-1;
    }

    .founder-picture {
      position: relative;
      display: flex;
      flex-direction: column;
      padding-left: 1rem;
      overflow: hidden;

      &::before {
        position: absolute;
        z-index: 1;
        width: 80%;
        aspect-ratio: 1 / 1;
        content: '';
        background-color: $color-1;
        border-radius: 50%;
        transform: translate(30%, 15%);
      }

      p {
        position: absolute;
        bottom: 1rem;
        z-index: 12;
        width: fit-content;
        padding: 6px 16px;
        font-weight: 700;
        color: white;
        background-color: $color-1;
        border-radius: 16px;
      }

      img {
        z-index: 10;
        display: flex;
        align-self: end;
      }
    }

    .founder-message {
      padding: 1rem;
    }
  }

  .our-vision,
  .our-mission {
    .header {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 95%;
      color: white;
      background-color: $color-2;
      border-radius: 1rem;

      img {
        filter: invert(1);
      }

      h2 {
        @extend %h2;
      }

      &::before {
        position: absolute;
        top: 0.5rem;
        left: 0.5rem;
        width: 100%;
        height: 95%;
        content: '';
        border: solid black 1px;
        border-radius: 1rem;
      }
    }
  }
}
