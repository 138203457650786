@import '../styles/abstract/colors';
@import '../styles/abstract/typography';

#magazine {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  .magazine-page {
    picture {
      width: 100%;
      object-fit: contain;

      img {
        width: 100%;
      }
    }
  }
  #magazine-page-5 {
    background-color: white;
  }

  .actionBtn {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    button {
      background-color: $color-1;
      color: white;
      @extend %h3;
      padding: 0.5rem 1rem;
      padding-inline: 1rem;
      padding-block: 0.5rem;
      border: none;
      border-radius: 0.5rem;
    }
  }
}
