#page.como-donar {
  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    padding-inline: 1rem;
    padding-block: 1rem;

    h2 {
      color: $color-1;
      @extend %font-2;
      font-weight: 700;
      font-size: 1.5;
      text-transform: uppercase;

      img {
        width: 80%;
      }
    }

    .mainSite {
      width: 100%;
      display: flex;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  section.pasos {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .paso {
      width: 95%;
      background-color: $pasos-color-1;
      border-radius: 10px;
      margin-top: 1rem;
      margin-block-start: 1rem;

      &.paso {
        &-1,
        &-2,
        &-3,
        &-4,
        &-5 {
          background-color: $pasos-color-1;
          padding: 1rem 0 0;
          padding-block-start: 1rem;
        }

        &-1,
        &-4 {
          display: flex;
          flex-direction: row;
        }

        &-2,
        &-5 {
          background-color: unset;
          display: grid;
          grid-template-areas:
            'paso imagen'
            'footer footer';

          .info {
            grid-area: paso;
            align-items: start !important;

            .paso-numero {
              border-top-left-radius: unset !important;
              border-top-right-radius: 1rem;
            }
          }

          img {
            grid-area: imagen;
          }
          .footer {
            margin-top: 1rem;
            margin-block-start: 1rem;
            grid-area: footer;
            display: flex;
            flex-direction: column;

            p {
              margin: 0;
              margin-block: 0;

              &:last-child {
                font-weight: 700;
                font-size: 90%;
              }
            }
          }
        }

        &-2 {
          padding-bottom: 0.5rem;
          padding-block-end: 0.5rem;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }

          .footer {
            p {
              &:last-of-type {
                width: 100%;
                font-size: 85%;
              }
            }
          }
        }

        &-3 {
          display: grid;
          grid-template-areas:
            'image info info'
            'footer footer footer';
          column-gap: 1rem;
          padding: 1rem 1rem 0;
          padding-inline: 1rem;

          img {
            width: 100%;
            grid-area: image;
          }
          .info {
            grid-area: info;
          }
          .footer {
            grid-area: footer;
            display: flex;
            justify-content: center;

            .paso-numero {
              border-top-right-radius: 10px;
            }
          }
        }

        &-4 {
          background-color: white;
        }

        &-5 {
          background-color: $pasos-color-1;
          padding: 1rem 0 0;
          padding-block-start: 1rem;

          .info {
            width: 90% !important;
          }

          .footer {
            padding: 0 1rem;
            padding-block-end: 1rem;
            padding-inline: 1rem;

            p {
              font-weight: 400;

              &:last-child {
                font-weight: inherit;
                @extend %p;
                font-size: $default-font-size;
              }
            }

            b {
              font-weight: 700;
            }
          }
        }

        img {
          padding: 0 0 1rem;
          padding-block-end: 1rem;
        }

        .info {
          width: 100%;
          min-height: 10px;
          display: flex;
          flex-direction: column;
          align-items: end;
          justify-content: end;

          p {
            @extend %p;
            font-weight: 400;

            span {
              font-weight: 700;
            }
          }
        }

        .paso-numero {
          width: 90%;
          display: flex;
          flex-direction: row;
          background-color: $color-2;
          padding: 0.5rem 1rem;
          padding-inline: 1rem;
          padding-block: 0.5rem;
          border-top-left-radius: 10px;
          align-items: center;
          justify-content: center;

          p {
            margin: 0;

            &:first-child {
              color: $pasos-numero;
              @extend %h2;
              margin-right: 0.5rem;
            }

            &:last-child {
              color: white;
              @extend %p;
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }

  section.closing {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin-top: 1.5rem;
    margin-block-start: 1.5rem;

    h3 {
      font-size: 2.5rem;
      color: $color-2;
      font-family: 'PT Serif', serif;
      font-weight: 400;
      line-height: 2.625rem;
    }

    p {
      @extend %p;
      margin: 0.5rem 0 1rem;
      margin-block-start: 0.5rem;
      margin-block-end: 1rem;
    }

    img.closing-illustration {
      padding: 1rem 0;
      padding-block: 1rem;
    }

    img.slogan {
      margin-top: 1rem;
      margin-block-start: 1rem;
    }
  }
}
