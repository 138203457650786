%font-1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}
%font-2 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

%h1 {
  @extend %font-1;

  font-size: 1.625rem;
}

%h2 {
  @extend %font-1;

  font-size: 1.5rem;
}

%h3 {
  @extend %font-1;

  font-size: 1.25rem;
}

$default-font-size: 1.125rem;
%p {
  @extend %font-2;

  font-size: $default-font-size;
}
